/* Página de Incio */
.container-portada{
  width: 100%;
  height: 600px;
  background-image: url(./Images/BgPortada2.jpg);
  background-repeat: no-repeat;
  background-size: 200%;
  animation: movimiento 50s infinite linear alternate;
  position: relative;
}
@keyframes movimiento {
  from{
    background-position: bottom left;
  }to{
    background-position: top right;
  }
}
.info_parteUno{
  width: 100%;
  height: 100vh;
}
.alejadoTop{
  padding-top: 5%;
}
.capa-gradient{
  width: 100%;
  height: 100%;
  position: absolute;
  background: -webkit-linear-gradient(left, black, #0672d0);
  opacity: 0.6;
}
.container-details{
  width: 100%;
  position: relative !important;
  margin: auto;
  color: white;
}
.details{
  width: 100%;
  font-size: 300%;
  padding-top: 15%;
  padding-left: 10%;
}
.glassmorfism{
  margin-left: 10%;
  margin-top: 5%;
  padding: 1.5em;
  font-size: 150%;
  position: absolute;
  border-radius: 10px;
  background: rgba(225, 225, 225, 0.30);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  border: 1px solid rgba(225, 225, 225, 0.18);
}
.btnCollapse{
  padding: 0; border: blue; background: none;
}
.whiteLetter{
  color: white;
  text-decoration: none;
}
.Questions{
  color: #2c4498;
  /* color: white; */
  text-align: start;
}
.cardBG{
  background: linear-gradient(220.55deg, #DDE4FF 0%, #8DA2EE 100%);
}
.Card{
  position: relative;
  width: 300px;
  height: 350px;
}
.Card .face{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
  transition: .5s;
}
.Card .front{
  transform: perspective(600px) rotateY(0deg);
  box-shadow: 0 5px 10px #000;
}
.Card .front img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Card .front h3 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
  line-height: 45px;
  color: #fff;
  background: rgba(0,0,0,.4);
  text-align: center;
}
.Card .back{
  transform: perspective(600px) rotateY(180deg);
  background: rgb(3,35,54);
  padding: 15px;
  color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  box-shadow: 0 5px 10px #000;
}
.Card .back h3 {
  font-size: 30px;
  margin-top: 20px;
  letter-spacing: 2px;
}
.Card:hover .front {
  transform: perspective(600px) rotateY(180deg);
}
.Card:hover .back {
  transform: perspective(600px) rotateY(360deg);
}

.card2{
  justify-content: center;
  align-items: center;
  display: flex;
  position:relative;
  padding-top: 50%;
  padding-bottom: 50%;
  text-align: center;
}
.card2 .face{
  position: absolute;
  backface-visibility: hidden;
  overflow: hidden;
  transition: .5s;
  text-align: center;
}
.card2 .front{
  margin-top: -5%;
  width: 100%;
  transform: perspective(600px) rotateY(0deg);
  line-height: 45px;
  text-align: center;
  position: absolute;
  object-fit: cover;
}
.card2 .back{
  /* margin-top: -25%; */
  position: absolute;
  margin-left: 5%;
  margin-right: 5%;
  transform: perspective(600px) rotateY(180deg);
  display: flex;
  flex-direction: column;
  text-align: center;
}
.card2:hover .front {
  transform: perspective(600px) rotateY(180deg);
}
.card2:hover .back {
  transform: perspective(600px) rotateY(360deg);
}

.centradoVertical{
  padding-top: 45%;
  padding-bottom: 45%;
  padding-left: 3%;
  padding-right: 3%;
}
.logoMenu{
  width: 10em;
}
.navbarGeneral{
  --shadow-color: 286deg 36% 56%;
  background: linear-gradient(220.55deg, #001C63 0%, #24CFC5 100%);
  --shadow-elevation-medium:
    0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
    0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
    5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);
}
.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgb(0 0 0 / 10%), inset 0 0.125em 0.5em rgb(0 0 0 / 15%);
}
.footer{
  margin-top: -2%;
  color: white;
  background: #667db6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.fondoAzul{
  background-color: #001C63;
}
.rservices{
  margin-top: 5%;
  margin-bottom: 5%;
}
.zoom:hover{
  transform: scale(1.15);
}
.blackLetter{
  color: #000;
}
.Caratula{
  animation: movimientoSB 2s infinite linear alternate;
  position: relative;
}
@keyframes movimientoSB {
  0%{ left: -2%;}
  100%{ left: 2%;}
}
.fondo{
  background-position: center;
  margin-top: 1%;
  width: 100%;
  height: 120vh;
  position:relative;
  background-image: linear-gradient(rgba(255, 255, 255, 0.912), rgba(255, 255, 255, 0.952)), url(./Images/construction_teamwork_icon.png);
  background-size:contain;
  background-repeat: no-repeat;
}
.serv_Fondo{
  background-position: center;
  margin-top: 1%;
  width: 100%;
  /* height: 200vh; */
  height: max-content;
  position:relative;
  background-image: linear-gradient(rgba(255, 255, 255, 0.912), rgba(255, 255, 255, 0.952)), url(./Images/icons/4417105_globe_dots_earth_cdn_connected_world.png);
  background-size:contain;
  background-repeat: no-repeat;
}
/* .c{
  font-size: 100%;
} */
.imageConstruction{
  width: 100%;
}
.mar-top3{
  margin-top: 3%;
}
.section{
  width: 45%;
  display: inline-block;
}
.aside{
  width: 45%;
  display: inline-block;
}
.infoVideo{
  padding-bottom: 10%;
}
.cuadros{
  text-align: center;
  align-items: center;
}
.cuadros:hover{
  /* background-color: rgba(0, 0, 0, .1); */
  --shadow-color: 286deg 36% 56%;
  background: linear-gradient(220.55deg, #001C63 0%, #24CFC5 100%);
  --shadow-elevation-medium:
    0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
    0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
    5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);
}
.primerColor{
  background-color: #8DA2EE;
}
.segundoColor{
  background-color: #0082c8;
}
.tercerColor{
  background-color: #2c4498;
}
.cuartoColor{
  background-color: #001C63;
}
.efiS_cuadros{
  margin-bottom: 5%;
}

@media (max-width: 1300px) {
  .footer{
    margin-top: -2.5%;
  }
  .container-portada{
    height: 900px;
  }
  .info_parteUno{
    width: 100%;
    height: 900px;
  }
  .Titulo{
    font-size: 300%;
  }
  .Caratula{
    padding-top: 5%;
    width: 50% !important;
  }
  .info_parteDos{
    padding-top: 0%;
    width: 90%;
  }
  .fondo{
    height: 90vh;
  }
  .c{
    font-size: 250%;
  }
}
@media (max-width: 1200px) {
  .footer{
    margin-top: -2.5%;
  }
  .container-portada{
    height: 1000px;
  }
  .info_parteUno{
    width: 100%;
    height: 100vh;
  }
  .Titulo{
    font-size: 300%;
  }
  .Caratula{
    padding-top: 5%;
    width: 50% !important;
  }
  .info_parteDos{
    padding-top: 0%;
    width: 90%;
  }
  .fondo{
    height: 100vh;
  }
  .c{
    font-size: 200%;
  }
}
@media (max-width: 900px) {
  .footer{
    margin-top: -3%;
  }
  /*Información*/
  .info_parteUno{
    height: 100vh;
  }
  .Titulo{
    font-size: 300%;
  }
  .Caratula{
    padding-top: 10%;
    width: 100% !important;
  }
  .info_parteDos{
    padding-top: 60%;
    width: 90%;
  }
  .fondo{
    height: 150vh;
  }
  .imageConstruction{
    width: 50%;
  }
  .centradoVertical{
    padding-top: 0%;
  }
  .c{
    font-size: 200%;
  }
}
@media (max-width: 800px) {
  .footer{
    margin-top: -11%;
  }
  .container-portada{
    height: 900px;
  }
  .info_parteUno{
    height: 900px;
  }
  /*Información*/
  .info_parteUno{
    height: 90vh;
  }
  .Titulo{
    font-size: 300%;
  }
  .Caratula{
    padding-top: 10%;
    width: 100% !important;
  }
  .fondo{
    height: 100vh;
  }
  .imageConstruction{
    width: 80%;
  }
  .centradoVertical{
    padding-top: 20%;
  }
  .c{
    font-size: 200%;
  }
}
@media (max-width: 780px) {
  .footer{
    margin-top: -3%;
  }
  .container-portada{
    height: 1000px;
    background-size: 500%;
  }
  /*Información*/
  .info_parteUno{
    height: 90vh;
  }
  .Titulo{
    font-size: 300%;
  }
  .Caratula{
    padding-top: 10%;
    width: 100% !important;
  }
  .info_parteDos{
    padding-top: 0%;
    width: 90%;
  }
}
@media (max-width: 600px) {
  .footer{
    margin-top: -5%;
  }
  .container-portada{
    height: 950px;
    background-size: 300%;
  }
  .info_parteUno{
    height: 950px;
    background-size: 300%;
  }
  .glassmorfism{
    top: 125%;
    margin-left: 10%;
    margin-right: 10%;
  }
  /*Información*/
  .info_parteUno{
    height: 70vh;
  }
  .Titulo{
    font-size: 300%;
  }
  .Caratula{
    padding-top: 10%;
    width: 100% !important;
  }
  .imageConstruction{
    width: 75%;
  }
  .centradoVertical{
    padding-top: 5%;
  }
  .fondo{
    height: 100vh;
  }
  .imageConstruction{
    width: 80%;
  }
  .centradoVertical{
    padding-top: 20%;
  }
  .c{
    font-size: 200%;
  }
}
@media (max-width: 500px) {
  .footer{
    margin-top: -5%;
  }
  .container-portada{
    height: 800px;
    background-size: 500%;
  }
  .details{
    padding-top: 50%;
    font-size: 200%;
  }
  .glassmorfism{
    top: 125%;
    margin: auto;
  }
  /*Información*/
  .info_parteUno{
    height: 65vh;
  }
  .Titulo{
    font-size: 300%;
  }
  .Caratula{
    padding-top: 10%;
    width: 100% !important;
  }
  .info_parteDos{
    padding-top: 80%;
    width: 90%;
  }
  .fondo{
    height: 120vh;
  }
  .imageConstruction{
    width: 50%;
  }
  .centradoVertical{
    padding-top: 5%;
  }
  .c{
    font-size: 100%;
  }
}
@media (max-width: 400px) {
  .footer{
    margin-top: -10%;
  }
  /*Información*/
  .info_parteDos{
    padding-top: 0%;
    width: 90%;
  }
  .fondo{
    height: 90vh;
  }
  .imageConstruction{
    width: 100%;
  }
  .centradoVertical{
    padding-top: 30%;
  }
  .c{
    font-size: 150%;
  }
}
@media (max-width: 350px) {
  .footer{
    margin-top: -10%;
  }
  .container-portada{
    height: 400px;
    background-size: 300%;
  }
  .details{
    padding-top: 10%;
    font-size: 200%;
  }
  .glassmorfism{
    top: 180%;
    margin: auto;
  }
  /*Información*/
  .info_parteUno{
    height: 80vh;
  }
  .Titulo{
    font-size: 200%;
  }
  .Caratula{
    padding-top: 0%;
    width: 100% !important;
  }
  .info_parteDos{
    padding-top: 0%;
    width: 90%;
  }
  .fondo{
    width: 100%;
    height: 100vh;
  }
  .imageConstruction{
    width: 75%;
  }
  .centradoVertical{
    padding-top: 10%;
  }
  .c{
    font-size: 120%;
  }
}
.a{
  min-height: 120px;
}
.b{
  width: 500px;
}